import React from 'react';
import * as readingTime from 'reading-time';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../layouts';

import { Comments, Footer, Header, Icon, Menu, Post, PreviousNext, SEO } from '../../components';

import * as S from './styles';

const BlogPostTemplate = ({ data, pageContext }) => {
  const readTime = readingTime(data.wpPost.content);
  const urlShare = typeof window !== 'undefined' ? window.location.href : null;
  const idYoutube = data.wpPost.acf.linkYoutube ? /v=([^&]+)/.exec(data.wpPost.acf.linkYoutube)[1] : '';

  return (
    <Layout>
      <>
        <SEO
          description={data.wpPost.excerpt}
          image={data.wpPost.featuredImage.node.localFile ? data.wpPost.featuredImage.node.localFile.childImageSharp.fluid.src : ''}
          title={data.wpPost.title}
        />
        <Header />
        <Menu postCategories={data.wpPost.categories} />

        <S.Content>
          {!data.wpPost.acf.linkYoutube && (
            <S.Banner>
              <Img
                alt={data.wpPost.featuredImage.node.altText}
                fluid={data.wpPost.featuredImage.node.localFile.childImageSharp.fluid}
                style={{ height: '100%' }}
                title={data.wpPost.featuredImage.node.title}
              />
              <S.Title>
                <S.Spotlight>{data.wpPost.title}</S.Spotlight>
              </S.Title>
            </S.Banner>
          )}

          {data.wpPost.acf.linkYoutube && (
            <S.IframeContent>
              <S.Iframe src={`https://www.youtube.com/embed/${idYoutube}`}></S.Iframe>
            </S.IframeContent>
          )}

          <S.TitleMobile className={data.wpPost.featuredImage.node.localFile ? '' : '-showDesktop'}>{data.wpPost.title}</S.TitleMobile>

          <S.Infos>
            <S.Author>
              <S.Avatar src={data.wpPost.author.node.avatar.url} alt={data.wpPost.author.node.name} />
              <S.Name>{data.wpPost.author.node.name}</S.Name>
            </S.Author>
            <S.Date>{data.wpPost.date}</S.Date>
            <S.TimeToRead>
              <S.Time>{parseInt(readTime.minutes)}</S.Time>
              minutos de leitura
            </S.TimeToRead>
          </S.Infos>

          <S.BlogPostContent dangerouslySetInnerHTML={{ __html: data.wpPost.content }} />

          {data.wpPost.categories.length > 0 && (
            <S.Categories>
              <Icon name="icon-tag" />

              {data.wpPost.categories.nodes.map((category, index) => {
                return (
                  <React.Fragment key={category.databaseId}>
                    {index ? ', ' : ''}
                    <S.Category cover direction="left" bg="#ffffff" duration={0.6} to={`/categorias/${category.slug}`}>
                      {category.name}
                    </S.Category>
                  </React.Fragment>
                );
              })}
            </S.Categories>
          )}

          <S.Share>
            <S.TitleShare>Compartilhar:</S.TitleShare>
            <S.Item href={`https://www.facebook.com/sharer/sharer.php?u=${urlShare}`} target="_blank">
              <Icon name="icon-facebook" />
            </S.Item>
            <S.Item href={`https://twitter.com/intent/tweet?url=${urlShare}`} target="_blank">
              <Icon name="icon-twitter" />
            </S.Item>
            <S.Item href={`https://api.whatsapp.com/send?text=${urlShare}`} target="_blank">
              <Icon name="icon-whatsapp" />
            </S.Item>
          </S.Share>

          <PreviousNext next={pageContext.nextPost} previous={pageContext.previousPost} />

          <S.LastPosts>
            <S.TitleLastPosts>Posts Relacionados</S.TitleLastPosts>

            <S.ContentPosts>
              {data.allWpPost.edges.map((post, index) => {
                return <Post post={post.node} key={index} />;
              })}
            </S.ContentPosts>
          </S.LastPosts>

          <Comments id={`${data.wpPost.databaseId}-${data.wpPost.slug}`} slug={data.wpPost.slug} title={data.wpPost.title} />
        </S.Content>
        <Footer />
      </>
    </Layout>
  );
};

export default BlogPostTemplate;
export const post = graphql`
  query($id: Int!, $categories: [Int!]!) {
    wpPost(databaseId: { eq: $id }) {
      acf {
        linkYoutube
      }
      author {
        node {
          avatar {
            url
          }
          databaseId
        }
      }
      categories {
        nodes {
          name
          slug
          databaseId
        }
      }
      content
      databaseId
      date(formatString: "D [de] MMMM [de] YYYY", locale: "pt-br")
      excerpt
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxHeight: 466, maxWidth: 1160) {
                ...GatsbyImageSharpFluid_withWebp
                src
              }
            }
          }
          title
        }
      }
      slug
      title
    }
    allWpPost(limit: 2, filter: { databaseId: { ne: $id }, categories: { nodes: { elemMatch: { databaseId: { in: $categories } } } } }) {
      edges {
        node {
          author {
            node {
              name
              avatar {
                url
              }
            }
          }
          categories {
            nodes {
              name
              slug
              databaseId
            }
          }
          date(formatString: "D [de] MMMM [de] YYYY", locale: "pt-br")
          excerpt
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  fluid(maxHeight: 310, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    src
                  }
                }
              }
              title
            }
          }
          databaseId
          slug
          title
        }
      }
    }
  }
`;
