import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { media } from '../../styles/media-queries';

export const Content = styled.main`
  margin: 0 auto;
  max-width: ${(props) => props.theme.grid.container};
  width: 100%;
`;

export const Banner = styled.div`
  max-width: ${(props) => props.theme.grid.container};
  overflow: hidden;
  position: relative;
  width: 100%;

  ${media.mobile`
    box-shadow: none;
    height: 200px;
    margin-bottom: 10px;
  `}

  ${media.tablet`
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    height: 466px;
    margin: 60px 0 30px;
  `}

  ${media.desktop`
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    height: 466px;
    margin: 60px 0 30px;
  `}
`;

export const IframeContent = styled.div`
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;

  ${media.mobile`
    margin-bottom: 10px;
  `}

  ${media.tablet`
    margin: 60px 0 30px;
  `}

  ${media.desktop`
    margin: 60px 0 30px;
  `}
`;

export const Iframe = styled.iframe`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.palette.black};
  margin: 0;
  width: 100%;
  position: absolute;
  z-index: 2;

  ${media.mobile`
    display: none;
  `}

  ${media.tablet`
    bottom: 20px;
    left: 0;
    padding: 15px 30px;
    position: absolute;
    text-align: left;
    z-index: 3;
  `}

  ${media.desktop`
    bottom: 20px;
    left: 0;
    padding: 15px 30px;
    position: absolute;
    text-align: left;
    z-index: 3;
  `}
`;

export const Spotlight = styled.span`
  background: ${(props) => props.theme.palette.white};
  border: 8px solid ${(props) => props.theme.palette.white};
  box-decoration-break: clone;
  display: inline;
  font-size: 40px;
  line-height: 56px;
`;

export const TitleMobile = styled.h1`
  color: ${(props) => props.theme.palette.black};
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 20px;
  padding: 0 15px 15px;
  text-align: center;

  ${media.tablet`
    display: none;
  `}

  ${media.desktop`
    display: none;
  `}

  &.-showDesktop {
    display: block;
    margin-top: 60px;
  }
`;

export const Infos = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;

  ${media.mobile`
    flex-flow: column;
    margin-bottom: 0;
  `}
`;

export const Author = styled.div`
  align-items: center;
  display: flex;

  &:after {
    background-color: ${(props) => props.theme.palette.black};
    content: '';
    height: 3px;
    margin: 0 10px;
    width: 3px;
  }

  ${media.mobile`
    margin-bottom: 15px;

    &:after {
      display: none;
    }
  `}
`;

export const Avatar = styled.img`
  border-radius: 100%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  height: 40px;
  margin-right: 15px;
  width: 40px;
`;

export const Name = styled.p`
  font-size: 17px;
  font-weight: bold;
  line-height: 16px;
  margin-top: -5px;
`;

export const Date = styled.div`
  align-items: center;
  display: flex;
  font-size: 17px;
  font-weight: bold;
  line-height: 16px;
  margin-top: -5px;

  &:after {
    background-color: ${(props) => props.theme.palette.black};
    content: '';
    height: 3px;
    margin: 5px 10px 0;
    width: 3px;
  }

  ${media.mobile`
    color: #848484;
    margin-bottom: 15px;

    &:after {
      display: none;
    }
  `}
`;

export const TimeToRead = styled.p`
  font-size: 17px;
  font-weight: bold;
  line-height: 16px;
  margin-top: -5px;

  ${media.mobile`
    margin-top: 0;
  `}
`;

export const Time = styled.span`
  display: inline-block;
  margin-right: 8px;
`;

export const BlogPostContent = styled.div`
  color: ${(props) => props.theme.palette.black};
  margin-bottom: 60px;

  strong {
    font-weight: bold;
  }

  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;

    p {
      display: inline;
    }

    &:before {
      color: #ccc;
      content: open-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }
  }

  figure {
    margin: 15px 0;
    text-align: center;

    &.wp-block-embed {
      clear: both;
    }

    > img {
      display: block;
      height: auto;
      margin: 0 auto;
      max-width: 100%;
    }

    figcaption {
      font-size: 13px;
      line-height: 18px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin-bottom: 15px;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 25px;
  }

  h4 {
    font-size: 23px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  p {
    font-size: 17px;
    line-height: 28px;
    margin: 15px 0;
  }

  ul li {
    font-size: 17px;
    line-height: 28px;
  }

  a {
    color: ${(props) => props.theme.palette.black};
    display: inline-block;
    position: relative;
    transition: width 0.3s linear 0.3s;

    &:after {
      background-color: ${(props) => props.theme.palette.black};
      color: ${(props) => props.theme.palette.white};
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 0;
      transition: width 0.3s linear;
      z-index: -2;
    }

    &:hover {
      color: ${(props) => props.theme.palette.white};

      &:after {
        width: 100%;
      }
    }
  }

  ul {
    padding: 15px 15px 10px;

    li {
      margin-bottom: 15px;
    }
  }

  .aligncenter,
  .alignleft,
  .alignright {
    display: block;
    padding: 0;
  }

  .aligncenter {
    float: none;
    margin: 0.5em auto 1em;
  }

  .alignright {
    float: right;
    margin: 0.5em 0 1em 1em;
  }

  .alignleft {
    float: left;
    margin: 0.5em 1em 1em 0;
  }

  .wp-caption {
    padding: 5px 0;
    border: 1px solid #555;
    background: #444;
    text-align: center;
  }

  .wp-caption img {
    display: inline;
  }

  .wp-caption p.wp-caption-text {
    margin: 5px 0 0;
    padding: 0;
    text-align: center;
    font-size: 75%;
    font-weight: 100;
    font-style: italic;
    color: #ddd;
  }

  .blocks-gallery-grid,
  .wp-block-gallery {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .blocks-gallery-image,
    .blocks-gallery-item,
    .blocks-gallery-image,
    .blocks-gallery-item {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      margin: 0 1em 1em 0;
      position: relative;
      width: calc(50% - 1em);

      figure {
        align-items: flex-end;
        display: flex;
        justify-content: flex-start;
        margin: 0;
        height: 100%;

        span {
          margin: 0 auto;
        }
      }
    }
  }

  ${media.mobile`
    padding: 15px;
  `}

  ${media.tablet`
    padding: 15px;
  `}

  ${media.desktop`
  `}
`;

export const Categories = styled.div`
  display: flex;
  justify-content: center;

  > svg {
    display: block;
    height: 15px;
    margin-right: 10px;
    transform: rotate(-45deg);
    width: 15px;
  }

  ${media.mobile`
    margin-bottom: 10px;
  `}

  ${media.tablet`
    margin-bottom: 10px;
  `}

  ${media.desktop`
    margin-bottom: 15px;
  `}
`;

export const Category = styled(AniLink)`
  color: ${(props) => props.theme.palette.black};
  display: block;
  line-height: 10px;
  margin-bottom: 40px;
  position: relative;
  text-decoration: none;

  &:after {
    background-color: ${(props) => props.theme.palette.black};
    bottom: -7px;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    transition: all 0.3s linear;
    width: 0;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
`;

export const Share = styled.div`
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.black};
  border-top: 1px solid ${(props) => props.theme.palette.black};
  clear: both;
  display: flex;
  justify-content: center;
  padding: 15px 0;
`;

export const TitleShare = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
  margin-right: 15px;
`;

export const Item = styled.a`
  background-color: ${(props) => props.theme.palette.white};
  border: 3px solid ${(props) => props.theme.palette.black};
  display: block;
  height: 35px;
  margin-right: 10px;
  transition: all 0.2s linear;
  width: 35px;

  > svg {
    display: block;
    fill: ${(props) => props.theme.palette.black};
    height: 30px;
    margin: 0 auto;
    transition: fill 0.2s linear;
    width: 20px;
  }

  &.-youtube > svg {
    height: 30px;
    width: 25px;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.black};

    > svg {
      fill: ${(props) => props.theme.palette.white};
    }
  }
`;

export const LastPosts = styled.div`
  padding: 60px 0;
`;

export const TitleLastPosts = styled.h2`
  margin-bottom: 30px;

  ${media.mobile`
    padding: 0 15px;
  `}

  ${media.tablet`
    padding: 0 15px;
  `}
`;

export const ContentPosts = styled.div`
  ${media.tablet`
    display: flex;
  `}

  ${media.desktop`
    display: flex;
  `}
`;
